import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import { AdminComponent } from './layouts/admin/admin.component';
import { SpaComponent } from './layouts/spa/spa.component';
import { SpComponent } from './layouts/sp/sp.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';




import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { JsauthserviceService } from './service/jsauthservice.service';
import { RequestserviceService } from './service/requestservice.service';
import { SpadataService } from './service/spadata.service';
import { AdminServiceService } from './admin/service/admin-service.service';

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    SpaComponent,
    SpComponent,
    AuthLayoutComponent,
  ],
  imports: [

    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule
  ],
  
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy},JsauthserviceService,
    RequestserviceService,SpadataService,AdminServiceService],
  bootstrap: [AppComponent]
})
export class AppModule { }
