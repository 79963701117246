import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminServiceService {

  constructor(private http: HttpClient) { }
  getSPUsers(){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.get(environment.appUrl+'admin1345/getsplistforadmin.do',{headers : reqHeader});
  }
  updatespflag(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'admin1345/updatespflag.do',data,{headers : reqHeader,responseType: 'text'});
  }
  getMotoUsers(){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.get(environment.appUrl+'admin1345/getmotoristList.do',{headers : reqHeader});
  }
  updatemotoflag(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'admin1345/updatemotoflag.do',data,{headers : reqHeader,responseType: 'text'});
  }
  getSPAUsers(){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.get(environment.appUrl+'admin1345/getSPAList.do',{headers : reqHeader});
  }
  getadmindashdetails(){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.get(environment.appUrl+'admin1345/admindashdata.do',{headers : reqHeader});
  }
}
