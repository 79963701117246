import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RequestserviceService } from 'src/app/service/requestservice.service';


declare const swal: any;
@Component({
  selector: 'app-sp',
  templateUrl: './sp.component.html',
  styleUrls: ['./sp.component.sass']
})
export class SpComponent implements OnInit {
  name:any=localStorage.getItem("name");
  garage:any=localStorage.getItem("spname");
  resultdata:any;
  imageurl:any="assets/images/user/usrbig3.jpg";
  logourl:any="assets/images/user/usrbig3.jpg";
  constructor(private route: Router,private reqservice:RequestserviceService) { }

  ngOnInit() {
    this.reqservice.getUserdetails().subscribe((rdata: any) => {
      if(rdata!=null){
        this.resultdata=rdata;
        if(this.resultdata.profpicurl!="" && this.resultdata.profpicurl!=null){
          this.imageurl="http://localhost:8080/Jumstart-backend/users/"+this.resultdata.id+"/profilepic.png"
        }
        if(this.resultdata.spdetails.logourl!="" && this.resultdata.spdetails.logourl!=null){
          this.logourl="http://localhost:8080/Jumstart-backend/users/"+this.resultdata.id+"/garagelogo.png"
        }
      }
    });
  }
  logout(){
    localStorage.clear();
    this.route.navigateByUrl('auth/login');
  }

  comingsoon(){
        swal("Approved!", "Coming Soon!", "error");

  }
}
