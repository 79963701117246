import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.sass']
})
export class AdminComponent implements OnInit {
  username:any=localStorage.getItem("user");;
  constructor(private route: Router) { }

  ngOnInit() {
  }
  logout(){
    localStorage.clear();
    this.route.navigateByUrl('auth/login');
  }
}
