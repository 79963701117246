import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { SpComponent } from './layouts/sp/sp.component';
import { SpaComponent } from './layouts/spa/spa.component';
import { AdminComponent } from './layouts/admin/admin.component';
const routes: Routes = [
    {
        path: 'auth',
        component: AuthLayoutComponent,
        loadChildren: './auth/auth.module#AuthModule'
    },
    {
        path: 'sp',
        component: SpComponent,
        loadChildren: './sp/sp.module#SpModule'
    },
    {
        path: 'spa',
        component: SpaComponent,
        loadChildren: './spa/spa.module#SpaModule'
    },
    {
        path: 'admin1345',
        component: AdminComponent,
        loadChildren: './admin/admin.module#AdminModule'
    },
    // {
    //     path: 'admin1345',
    //     component: AdminComponent,
    //     loadChildren: './admin/admin.module#AdminModule'
    // },
    {
        path: '',
        redirectTo: 'auth',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
