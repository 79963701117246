import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JsauthserviceService {


  reqHeader =  new HttpHeaders({'Content-Type': 'application/json'});
  constructor(private http: HttpClient) { }
  userlogin(data: any){
    return this.http.post(environment.appUrl+'userlogin.do', data, {headers : this.reqHeader});
  }
  userregister(data: any){
    return this.http.post(environment.appUrl+'createuser.do', data, {headers : this.reqHeader,responseType: 'text'});
  }
  adminuserlogin(data: any){
    return this.http.post(environment.appUrl+'admin1345/adminuserlogin.do', data, {headers : this.reqHeader});
  }

  getSpecialServices(){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.get(environment.appUrl+'getspecialServices.do', {headers : reqHeader});
  }

  getLocation(data: any){
    return this.http.post(environment.appUrl+'getlocation.do', data, {headers : this.reqHeader,responseType: 'text'});
  }
}
