import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SpadataService {
  
  Uploadchatimage(data: any) {
    var reqHeader = new HttpHeaders({'Authorization':"Bearer "+localStorage.getItem("token")});
   
    return this.http.post(environment.appUrl+'uploadchatimageBySP.do', data, {headers : reqHeader,responseType: 'text'});

  }

  constructor(private http: HttpClient) { }
  getSpServices(){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.get(environment.appUrl+'listrequestsbyspaid.do', {headers : reqHeader});
  }
  SaveSpaServices(data: any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'AddServiceBySP.do', data, {headers : reqHeader,responseType: 'text'});
  }
  UpdateSpaService(data: any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'UpdateServiceBySPA.do', data, {headers : reqHeader,responseType: 'text'});

  }
  DeleteSpaService(data: any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'DeleteServiceBySP.do', data, {headers : reqHeader,responseType: 'text'});

  }
  getRequestById(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'getrequestbyid.do', data, {headers : reqHeader});
  }
  getSpaServices(){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.get(environment.appUrl+'listspaservicebyid.do', {headers : reqHeader});
  }
  getspaservicesbyid(){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.get(environment.appUrl+'listspaservicebyid.do',{headers : reqHeader});
  }
  EditSpaService(data: any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'EditServiceBySPA.do',data, {headers : reqHeader});
  }

  getExpenseDetails(){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.get(environment.appUrl+'ListALLExpensesofSPA.do', {headers : reqHeader});
  }
  SaveSpaExpenseDetails(data: any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'AddExpenseofSPA.do', data, {headers : reqHeader,responseType: 'text'});
  } 
  EditSpaExpense(data: any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'EditExpenseBySPA.do',data, {headers : reqHeader});
  } 
  UpdateSpaExpense(data: any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'UpdateExpenseofSPA.do', data, {headers : reqHeader,responseType: 'text'});

  }
  DeleteSpaExpense(data: any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'DeleteExpenseBySPA.do', data, {headers : reqHeader,responseType: 'text'});

  }
  saveworkcard(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'createjobcardBySPA.do', data, {headers : reqHeader,responseType: 'text'});
  }
  getworkcardlist(){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.get(environment.appUrl+'listworkcardsbyspa.do',{headers : reqHeader});
  }
  getworkcardbyid(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'EditjobcardBySPA.do', data, {headers : reqHeader});
  }
  updateWorkCard(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'UpdatejobcardBySPA.do', data, {headers : reqHeader,responseType: 'text'});
  }
  listmysps(){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.get(environment.appUrl+'ListMySPdetailsforSPA.do',{headers : reqHeader});
  }
  savemessage(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'savechatmessage.do', data, {headers : reqHeader,responseType: 'text'});
  }
  loadmessage(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'listchatmessage.do', data, {headers : reqHeader});
  }

  loadvehiclemake(){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
       return this.http.get(environment.appUrl+'getmakedetails.do',{headers : reqHeader})
  }

  loadmodel(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+"loadmodel.do",data,{headers : reqHeader} );
  }
  
  loadvariants(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+"loadvariants.do",data,{headers : reqHeader} );
  }

  loadjobcardlist(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'loadjobcardbyid.do', data, {headers : reqHeader})
  }


}
